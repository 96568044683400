import React from 'react'
//import Header from './Header'
import Footer from './Footer'

const Layout = ({ isHomepage, children, navigation, footerContent }) => (
  <>
    {/* <Header navigation={navigation} /> */}
      {children}
    <Footer footerContent={footerContent}/>
  </>
)

export default Layout